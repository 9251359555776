// Entry point for the build script in your package.json
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
ActiveStorage.start()
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true)
var ReactRailsUJS = require("react_ujs")
ReactRailsUJS.useContext(componentRequireContext)

console.log("BLEEEAT B*TCHES")
console.log(
  "WARNING: The ordering process is a complex multi-sig system with various validations in place. DO NOT send ADA to the payment wallet expecting NFTs like a typical 'address drop'. Only multi-sig txs and orders will be valid. We are not responsible for funds lost due to misusing the system. LFGOAT"
)
